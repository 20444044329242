<template>
  <v-container>
    <v-snackbar
      v-model="snackbar"
      :color="snackbarColor"
      :multi-line="true"
      :timeout="timeout"
    >
      {{ errorText }}
    </v-snackbar>
    <v-dialog hide-overlay no-click-animation persistent v-model="updateDialog" width="500">
      <v-card elevation="8">
        <v-card-title style="background-color: #003366"
          ><span style="color: white">Conferma</span> <v-spacer></v-spacer
          ><v-btn icon @click="updateDialog = false"
            ><v-icon style="color: white">mdi-close-thick</v-icon></v-btn
          ></v-card-title
        >
        <v-card-text>
          <v-file-input
            ref="imageUpload"
            v-model="attFilename"
            accept="*/*"
            label="Aggiungi allegato"
            @change="setFile"
          />
          <v-textarea v-model="comment" label="Note"></v-textarea>
        </v-card-text>
        <v-card-title v-if="sendEvent"
          >{{ getUserEventText(sendEvent) }}<br />Confermi?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="ma-2"
            color="primary"
            :disabled="needEvent()"
            @click="updatePurchase(purchase.ID, sendEvent, sendMethod)"
            >OK</v-btn
          >
          <v-btn
            class="ma-2"
            color="primary"
            @click="
              updateDialog = false;
              timerDate = null;
            "
            >Annulla</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog hide-overlay no-click-animation persistent  v-if="globalStep" v-model="detailsDialog" width="500">
      <v-card>
        <v-card-title style="background-color: #003366"><span style="color: white">Dettagli</span></v-card-title>
        <v-card-text>
          <v-timeline dense>
            <v-timeline-item
              small
              v-for="(inside, i) in globalStep.insideSteps.reverse()"
              :key="'ciccio' + i"
              :color="myFSM.states[inside.state].meta.color"
            >
              <template v-slot:icon>
                <v-icon small dark>mdi-arrow-up</v-icon>
              </template>
              <v-card elevation="4">
                <span>{{ inside.User.COGNOME }} {{ inside.User.NOME }}</span
                >:<br />{{ formatStep(inside) }}<br />
                <span
                  v-if="
                    inside.comment &&
                    inside.comment !== null &&
                    inside.comment !== ''
                  "
                  >Note: {{ inside.comment }}</span
                ><br />
                <span
                    v-if="
                      inside.financialAssignee &&
                      inside.financialAssignee !== null &&
                      inside.financialAssignee !== ''
                    "
                    >Assegnato a: {{ inside.financialAssignee.COGNOME }}
                    {{ inside.financialAssignee.NOME }}</span
                  >
                  <br/>
                <span v-if="inside.attachment"
                  >Allegato:
                  <a
                    v-if="!attachmentDeleted(inside.attachment)"
                    @click="downloadAttachment(inside.attachment)"
                    >{{ attachmentFilename(inside.attachment) }}</a
                  ><span v-else class="text-decoration-line-through">{{
                    attachmentFilename(inside.attachment)
                  }}</span></span
                ><br />
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="detailsDialog = false">Chiudi</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row v-if="loading">
      <v-spacer></v-spacer>
      <v-col cols="1"
        ><v-progress-circular
          color="primary"
          indeterminate
          :size="70"
          :width="7"
      /></v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row v-else-if="error">
      <v-spacer></v-spacer>
      <v-col cols="6"
        ><v-alert type="error">{{ errorText }}</v-alert></v-col
      >
      <v-spacer></v-spacer>
    </v-row>
    <v-row v-else>
      <v-col cols="12" lg="6">
        <v-card elevation="8" v-if="myFSM">
          <v-card-title>Richiesta: {{ purchaseDescription }} </v-card-title>
          <v-card-text>
            <strong>
              Stato attuale:
              {{ myFSM.states[currentState].meta.fullStateName }}<br />
              Utente (Richiedente):
              <span v-for="(user, j) of purchase.Users" :key="j"
                >{{ user.COGNOME }} {{ user.NOME
                }}<span v-if="j !== purchase.Users.length - 1">, </span> </span
              ><br />
              <span v-if="currentMethod"
                >Tipologia di acquisto: {{ currentMethodText }}<br
              /></span>
              <span v-if="!purchase.closed"
                >Attualmente assegnato a:
                {{ myFSM.states[currentState].meta.responsible }}<span
                  v-if="
                    financialAssignee &&
                    myFSM.states[currentState].meta.financial
                  "
                  >, {{ financialAssignee.COGNOME }}
                  {{ financialAssignee.NOME }}</span
                ></span
              ></strong
            >
            <br />
            <br />
            <v-expansion-panels v-if="attachments.length > 0">
                <v-expansion-panel>
                 <v-expansion-panel-header>
              <v-row>
                <v-col cols="3" align-self=center><strong>Allegati:</strong><v-chip small >
                  {{attachments.length}}</v-chip></v-col>
                <v-col cols="3" align-self=center><strong>inserito:</strong></v-col>
                <v-col cols="3" align-self=center><strong>da:</strong></v-col>
                <v-col cols="3" align-self=center><strong>nella fase:</strong></v-col>
              </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content
                v-for="attachment of attachments"
                :key="attachment.id"
                dense
                :class="
                  attachment.deleted ? 'text-decoration-line-through text--disabled' : ''
                "
              >
              <v-row :class="
                          attachment.starred
                            ? 'font-weight-bold grey lighten-3'
                            : ''
                        ">
                <v-col cols="3"
                  >-&nbsp;<a
                    v-if="!attachment.deleted"
                    @click="downloadAttachment(attachment.id)"
                    >{{ attachment.ORIGINAL_FILENAME }}</a
                  ><span v-else>{{ attachment.ORIGINAL_FILENAME }}</span></v-col
                >
                <v-col cols="3">{{ formatTime(attachment.state.date) }}</v-col>
                <v-col cols="3"
                  >{{ attachment.state.User.COGNOME }}
                  {{ attachment.state.User.NOME }}</v-col
                >
                <v-col cols="3">{{
                  myFSM.states[attachment.state.state].meta.fullStateName
                }}</v-col>
              </v-row>
              </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            
            <br />
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="8">
                {{ myFSM.states[currentState].meta.userInteractionText }}
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
          </v-card-text>
          <v-card-actions
            v-if="myFSM.states[currentState].meta.userInteraction"
          >
            <v-row>
              <v-spacer></v-spacer>
              <v-col
                cols="2"
                v-for="button of myFSM.states[currentState].meta.userButtons"
                :key="button.text"
              >
                <v-btn
                  dark
                  color="primary"
                  @click="
                    comment = null;
                    sendEvent = button.event ? button.event : computedEvent;
                    sendMethod = button.method;
                    notifyUsers = true;
                    notifyGroup = true;
                    updateDialog = true;
                  "
                  >{{ button.text }}</v-btn
                >
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
            <br />
          </v-card-actions>
          <br />
        </v-card>
      </v-col>
      <v-col cols="12" lg="6">
        <v-card elevation="8">
          <v-card-title>Timeline</v-card-title>
          <v-card-text>
            <v-timeline v-if="purchase" :reverse="false">
              <v-timeline-item
                v-for="(step, l) in reducedReversedHistory"
                :key="l"
                :class="l % 2 ? '' : 'text-right'"
                small
                fill-dot
                :color="myFSM.states[step.state].meta.color"
              >
                <template v-slot:icon>
                  <v-icon medium dark>mdi-arrow-up</v-icon>
                </template>
                <template v-slot:opposite>
                  <span>{{ formatStep(step) }}</span
                  ><br />{{ getEventText(step.event) }}
                </template>
                <v-alert
                  :value="true"
                  :color="myFSM.states[step.state].meta.color"
                  class="white--text"
                >
                  <strong>{{
                    myFSM.states[step.state].meta.fullStateName
                  }}</strong
                  ><br />
                  <strong>{{
                    myFSM.states[step.state].meta.responsible
                  }}</strong>
                  <br />
                  <br />
                  <v-btn
                    @click="
                      globalStep = step;
                      detailsDialog = true;
                    "
                    >Dettagli</v-btn
                  >
                </v-alert>
              </v-timeline-item>
            </v-timeline>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
const moment = require("moment-timezone");
import { mapState } from "vuex";

export default {
  name: "MyPurchase",

  data: () => ({
    financialAssignee: null,
    currentFile: null,
    attFilename: null,
    comment: null,
    event: null,
    updating: false,
    method: null,
    myFSM: null,
    purchase: null,
    purchaseDescription: "",
    reducedHistory: [],
    reducedReversedHistory: [],
    currentState: null,
    currentMethod: null,
    currentMethodText: null,
    detailsDialog: false,
    updateDialog: false,
    globalStep: null,
    attachments: [],
    selectedMethod: null,
    sendEvent: null,
    sendMethod: null,
    timerDate: null,
    dateDialog: false,
    notifyGroup: true,
    notifyUsers: false,
    groupNotifyLabel: "",
    loading: false,
    snackbar: false,
    snackbarColor: "",
    errorText: "",
    timeout: 5000,
    error: false,
  }),
  computed: {
    ...mapState(["userInitials", "userRole"]),
    computedEvent() {
      return this.myFSM.context.method === "amazon"
        ? "waitForAmazonApproval"
        : this.myFSM.context.method === "quadro"
        ? "waitForQuadroApproval"
        : this.myFSM.context.method === "direct"
        ? "waitForDirectApproval"
        : this.myFSM.context.method === "consip"
        ? "waitForCONSIPApproval"
        : this.myFSM.context.method === "rdo"
        ? "waitForRDOApproval"
        : "";
    },
  },
  created: function () {
    this.loadPurchase();
  },
  methods: {
    selectAllGroupUsers(state) {
      this.currentGroupUsers = this.myFSM.states[state].ownersGroupUsers;

      this.selectedGroupUsers = [];
      for (let user of this.myFSM.states[state].ownersGroupUsers) {
        this.selectedGroupUsers.push({
          matricola: user.MATRICOLA,
          fullname: user.COGNOME + " " + user.NOME,
        });
      }
    },
    downloadAttachment(attachment) {
      const headers = {
        authorization: "Bearer " + this.$store.state.authJwt,
      };

      this.$http
        .get(
          process.env.VUE_APP_PATRIMONIO_API +
            "/users/" +
            this.$store.state.userMatricola +
            "/attachment/" +
            attachment,
          {
            // This is very important to avoi white page pdf
            responseType: "arraybuffer",
            headers,
          }
        )
        .then((response) => {
          let contentType = response.headers["content-type"];
          let blob = new Blob([response.data], { type: contentType });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = this.attachmentFilename(attachment);
          link.click();
        })
        .catch((error) => {
          // handle error
          console.log(error);
          // this.permSnackbar = true;
          // this.snackbarColor = "error";
          // this.errorText = error.response.data;
          // this.timeout= -1;
        });
    },
    attachmentDeleted(attachment) {
      for (let att of this.attachments) {
        if (att.id === attachment) {
          return att.deleted;
        }
      }
      return false;
    },
    getEventText(eventName) {
      // console.log(eventName);
      // console.log(this.myFSM.states)

      for (const [key, state] of Object.entries(this.myFSM.states)) {
        if (state.meta.buttons) {
          for (let button of state.meta.buttons) {
            if (button.event && button.event === eventName) {
              return button.description;
            }
          }
        }
        if (state.meta.selection) {
          for (let sel of state.meta.selection) {
            if (sel.event && sel.event === eventName) {
              return sel.description;
            }
          }
        }
        if (state.on) {
          for (const [eventKey, stateEvent] of Object.entries(state.on)) {
            if (eventKey === eventName) {
              // Scan buttons for empty event
              if (state.meta.buttons) {
                for (let button of state.meta.buttons) {
                  if (!button.event) {
                    return button.text;
                  }
                }
              }
            }
          }
        }
      }
      return eventName;
    },
    getUserEventText(eventName) {
      // console.log(eventName);
      // console.log(this.myFSM.states)

      for (const [key, state] of Object.entries(this.myFSM.states)) {
        if (state.meta.userButtons) {
          for (let button of state.meta.userButtons) {
            if (button.event && button.event === eventName) {
              return button.description;
            }
          }
        }
        if (state.on) {
          for (const [eventKey, stateEvent] of Object.entries(state.on)) {
            if (eventKey === eventName) {
              // Scan buttons for empty event
              if (state.meta.userButtons) {
                for (let button of state.meta.userButtons) {
                  if (!button.event) {
                    return button.text;
                  }
                }
              }
            }
          }
        }
      }
      return eventName;
    },
    attachmentFilename(attachment) {
      for (let att of this.attachments) {
        if (att.id === attachment) {
          return att.ORIGINAL_FILENAME;
        }
      }
      return "NONE";
    },
    loadPurchase() {
      this.loading = true;
      this.$http
        .get(
          process.env.VUE_APP_PATRIMONIO_API +
            "/users/" +
            this.$store.state.userMatricola +
            "/purchase/" +
            this.$route.params.purchase,
          {
            headers: {
              authorization: "Bearer " + this.$store.state.authJwt,
            },
          }
        )
        .then((response) => {
          this.myFSM = response.data.fsm;
          this.financialAssignee = response.data.financialAssignee;
          this.sendEvent = null;
          this.purchase = response.data;
          if (this.purchase.PurchaseHistories.length > 0) {
            this.currentState =
              this.purchase.PurchaseHistories[
                this.purchase.PurchaseHistories.length - 1
              ].state;
            this.currentMethod =
              this.purchase.PurchaseHistories[
                this.purchase.PurchaseHistories.length - 1
              ].method;
              this.currentMethodText =
              this.myFSM.context.methods[
                this.purchase.PurchaseHistories[
                  this.purchase.PurchaseHistories.length - 1
                ].method
              ];
          }
          this.reducedHistory = this.reduceHistory(
            this.purchase.PurchaseHistories
          );
          this.reducedReversedHistory = this.reducedHistory.slice().reverse();
          this.attachments = this.purchase.Attachments.reverse();
          for (let attachment of this.purchase.Attachments) {
            for (let step of this.purchase.PurchaseHistories) {
              if (step.attachment === attachment.id) {
                attachment.state = step;
                break;
              }
            }
          }
          this.purchaseDescription = this.purchase.description;
          this.userNotifyLabel = "Notifica ";
          for (let user of this.purchase.Users) {
            this.userNotifyLabel += user.COGNOME + " " + user.NOME + " ";
          }
          this.loading = false;
          this.error = false;
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.data && err.response.data.errorIT) {
              this.errorText = err.response.data.errorIT;
            } else if (err.response.data && err.response.data.error) {
              this.errorText = err.response.data.error;
            } else {
              this.errorText = err.response.statusText;
            }
            this.snackbarColor = "red";
            this.snackbar = true;
          } else if (err.request) {
            this.errorText = "Errore in caricamento acquisti";
            this.snackbarColor = "red";
            this.snackbar = true;
          } else {
            console.log(err);
          }
          this.error = true;
          this.loading = false;
        });
    },
    reduceHistory(history) {
      let reduced = [];
      let prevStatus = null;
      for (let step of history) {
        if (prevStatus) {
          if (prevStatus === step.state) {
            // TODO: Expand current status
            reduced[reduced.length - 1].insideSteps.push(step);
          } else {
            reduced.push(step);
            reduced[reduced.length - 1].insideSteps = [];
            reduced[reduced.length - 1].insideSteps.push(step);
          }
        } else {
          reduced.push(step);
          reduced[reduced.length - 1].insideSteps = [];
          reduced[reduced.length - 1].insideSteps.push(step);
        }
        prevStatus = reduced[reduced.length - 1].state;
      }

      return reduced;
    },
    formatStep(step) {
      return moment(step.date)
        .tz("Europe/Rome")
        .locale("IT")
        .format("D MMMM YYYY, H:mm:ss");
    },
    formatDate(date) {
      return !date
        ? null
        : moment(date).tz("Europe/Rome").locale("IT").format("D MMMM YYYY");
    },
    formatTime(date) {
      return !date
        ? null
        : moment(date)
            .tz("Europe/Rome")
            .locale("IT")
            .format("D MMMM YYYY, H:mm:ss");
    },
    needEvent() {
      if (!this.myFSM || !this.sendEvent || !this.currentState) {
        return false;
      }

      if (
        this.myFSM.states[
          this.myFSM.states[this.currentState].on[this.sendEvent].target
        ].meta.timer &&
        this.sendEvent &&
        !this.timerDate
      ) {
        return true;
      }
    },
    updatePurchase(currentPurchase, event, method) {
      this.updating = true;
      let fd = new FormData();
      fd.append("ciccio", 1234);
      var component = this;
      if (this.currentFile && this.currentFile !== "") {
        fd.append("file", this.currentFile);
      }
      if (method && method !== "") {
        fd.append("method", method);
      }
      if (event && event !== "") {
        fd.append("event", event);
      }
      fd.append("notifyGroup", true);
      fd.append("notifyUsers", true);
      if (this.comment && this.comment !== "") {
        fd.append("comment", this.comment);
      }
      fd.append("purchase", currentPurchase);

      this.$http
        .put(
          process.env.VUE_APP_PATRIMONIO_API +
            "/users/" +
            this.$store.state.userMatricola +
            "/purchase/" +
            this.$route.params.purchase,
          fd,
          {
            headers: {
              authorization: "Bearer " + this.$store.state.authJwt,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(function () {
          component.loadPurchase();
          component.attFilename = null;
          component.comment = "";
          component.selectedMethod = null;
          component.updating = false;
          component.updateDialog = false;
        })
        .catch(function (response) {
          console.log(response);
          component.updating = false;
          component.updateDialog = false;
        });
    },
    setFile(file) {
      this.currentFile = file;
    },
  },
};
</script>
